<template>
    <v-list-group>
        <template v-slot:activator>
            <v-list-item-action>
                <v-icon>mdi-cash-register</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    Operações de Caixa
                </v-list-item-title>
                <div style="display: flex;">
                    <span :class="isCaixaAberto ? 'circle-green' : 'circle-red'" />
                    <span class="caixa-status ml-1">
                        {{ `Caixa ${isCaixaAberto ? 'Aberto' : 'Fechado'}` }}
                    </span>
                </div>
            </v-list-item-content>
        </template>

        <div style="margin-left: 20px;">
            <v-list-item link to="/caixa">
                <v-list-item-action>
                    <v-icon>mdi-cash</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Pedidos em Aberto</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isCaixaAberto" @click="fecharCaixa">
                <v-list-item-action>
                    <v-icon>mdi-minus-circle-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Fechar Caixa</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-else @click="abrirCaixa">
                <v-list-item-action>
                    <v-icon>mdi-flag</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Abrir Caixa</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="isCaixaAberto"
                @click="movimentacao(caixaTiposOperacao.SUPRIMENTO)"
            >
                <v-list-item-action>
                    <v-icon>mdi-cash-plus</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Inserir Dinheiro</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="isCaixaAberto"
                @click="movimentacao(caixaTiposOperacao.SANGRIA)"
            >
                <v-list-item-action>
                    <v-icon>mdi-cash-minus</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Retirar Dinheiro</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isAdmin" link to="/caixa-historico">
                <v-list-item-action>
                    <v-icon>mdi-history</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Histórico</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </div>
        <CaixaOperacoes ref="caixaOperacoes" />
    </v-list-group>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import caixaOperations from '@/utils/caixaOperations';
import CaixaOperacoes from '../../Caixa/CaixaOperacoes.vue';

export default {
    name: 'CaixaMenuListGroup',

    components: {
        CaixaOperacoes,
    },

    data: () => ({
        caixaTiposOperacao: caixaOperations,
    }),

    computed: {
        ...mapState([
            'isCaixaAberto',
            'usuario',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },
    },

    methods: {
        ...mapMutations({
            setCaixaAberto: 'setCaixaAberto',
        }),

        async abrirCaixa() {
            const title = 'Abertura de Caixa';
            const message = 'Informe o valor inicial em dinheiro';
            const type = this.caixaTiposOperacao.ABERTURA;

            const {
                confirm,
                valor,
            } = await this.$refs.caixaOperacoes.open(title, message, type);

            if (!confirm) {
                return;
            }

            this.$http.post('caixa/abertura', { valor }).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.setCaixaAberto(true);
                this.notify('Abertura de Caixa concluída');
            }).catch(() => this.notify('Não foi possivel concluir a operação', 'warning'));
        },

        async fecharCaixa() {
            const title = 'Fechamento de Caixa';
            const message = '';
            const type = this.caixaTiposOperacao.FECHAMENTO;

            const {
                confirm,
                valor,
                valor_final,
                observacao,
            } = await this.$refs.caixaOperacoes.open(title, message, type);

            if (!confirm) {
                return;
            }

            this.$http.post('caixa/fechamento', { valor, valor_final, observacao }).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.setCaixaAberto(false);
                this.notify('Fechamento de Caixa concluído');
            }).catch(() => this.notify('Não foi possivel concluir a operação', 'warning'));
        },

        async movimentacao(tipo) {
            const title = tipo === this.caixaTiposOperacao.SUPRIMENTO
                ? 'Inserir Dinheiro no Caixa'
                : 'Retirar Dinheiro do Caixa';
            const message = '';

            const {
                confirm,
                valor,
                observacao,
            } = await this.$refs.caixaOperacoes.open(title, message, tipo);

            if (!confirm) {
                return;
            }

            this.$http.post('caixa/movimento', { valor, observacao, tipo }).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Concluído com sucesso');
            }).catch(() => this.notify('Não foi possivel concluir a operação', 'warning'));
        },
    },
}
</script>

<style scoped>
.circle-green {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #4caf50;
    display: inline-block;
}

.circle-red {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: red;
    display: inline-block;
}

.caixa-status {
    font-size: 12px;
    font-weight: 500;
    color: #666;
}
</style>