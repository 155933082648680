<template>
    <v-dialog
        v-model="dialog"
        :width="getWidth"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
        @keydown.enter="agree"
        persistent
    >
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span>{{ title }}</span>
                <v-spacer/>
                <v-btn icon @click.native="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle v-if="message" class="mt-2">{{ message }}</v-card-subtitle>
            <v-card-text
                align="center"
                justify="center"
                :style="`height: ${getHeight}px;`"
            >
                <v-progress-circular
                    v-if="loading"
                    :size="50"
                    color="primary"
                    style="margin: 60px 0 60px 0;"
                    indeterminate
                />
                <v-simple-table v-if="isFechamento && !loading" class="formas-pgto-table">
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="(pgto, index) in saldos" :key="index">
                                <td>{{ pgto.descricao }}</td>
                                <td><b>R$ {{ formatPrice(pgto.saldo) }}</b></td>
                                <td>
                                    <v-text-field
                                        :ref="`valor${pgto.id}`"
                                        v-model="pgto.valor"
                                        prefix="R$"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        placeholder="0.00"
                                        outlined
                                        dense
                                        hide-details
                                        class="mt-2"
                                        style="width: 120px;"
                                    />
                                </td>
                                <td>
                                    <v-chip
                                        :color="`${pgto.valor - pgto.saldo == 0 ? 'green' : 'red'} lighten-5`"
                                        :text-color="`${pgto.valor - pgto.saldo == 0 ? 'green' : 'red'}`"
                                        style="width: 95px; display: flex; justify-content: center;"
                                    >
                                        <b>R$ {{ formatPrice(pgto.valor - pgto.saldo)  }}</b>
                                    </v-chip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div
                    v-if="isFechamento"
                    style="display: flex; justify-content: space-between;"
                    class="mx-2 mt-2 mb-8"
                >
                    <div>
                        <h4>
                            Diferença Total:
                        </h4>
                    </div>
                    <div>
                        <h4 :class="`${totalDiff == 0 ? 'green' : 'red'}--text`">
                            R$ {{ formatPrice(totalDiff) }}
                        </h4>
                    </div>
                </div>
                <v-text-field
                    v-else
                    ref="valor"
                    label="Valor"
                    v-model="valor"
                    prefix="R$"
                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                />
                <v-textarea
                    ref="observacao"
                    v-if="!isAbertura"
                    :label="getObsFieldLabel"
                    v-model="observacao"
                    rows="4"
                    clear-icon="mdi-backspace-outline"
                    clearable
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                <v-spacer />
                <v-btn
                    :color="options.color"
                    @click.native="agree"
                    class="white--text"
                >Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import caixaOperations from '@/utils/caixaOperations';

export default {
    name: 'CaixaOperacoes',

    data: () => ({
        loading: false,
        saldos: [],
        valor: null,
        observacao: null,
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        type: null,
        options: {
            color: 'primary',
            width: 520,
            zIndex: 200,
        },
    }),

    computed: {
        isAbertura() {
            return this.type === caixaOperations.ABERTURA;
        },

        isFechamento() {
            return this.type === caixaOperations.FECHAMENTO;
        },

        isSangria() {
            return this.type === caixaOperations.SANGRIA;
        },

        isSuprimento() {
            return this.type === caixaOperations.SUPRIMENTO;
        },

        getObsFieldLabel() {
            return this.isSangria || this.isSuprimento
                ? 'Motivo' : 'Observação';
        },

        totalDiff() {
            return this.saldos.reduce((a, b) => a + ((b.valor || 0)  - b.saldo), 0);
        },

        getHeight() {
            if (this.isFechamento) {
                return 450;
            }

            if (this.isAbertura) {
                return 120;
            }

            return 230;
        },

        getWidth() {
            return this.isFechamento ? 600 : 450;
        },
    },

    methods: {
        open(title, message, type, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.type = type;
            this.options = Object.assign(this.options, options);
            this.valor = null;
            this.observacao = null;

            this.init();

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            if (this.isFechamento) {
                const invalidValue = this.saldos.find(e => (e.valor === null || e.valor === ''));
                if (invalidValue) {
                    this.notify('Preencha o valor recebido em ' + invalidValue.descricao, 'warning');
                    const ref = `valor${invalidValue.id}`;
                    setTimeout(() => this.$refs[ref][0].focus(), 100);
                    return;
                }

                if (!this.observacao && this.totalDiff != 0) {
                    this.notify('Informe uma observação', 'warning');
                    setTimeout(() => this.$refs.observacao.focus(), 100);
                    return;
                }
            } else {
                if (!this.valor) {
                    this.notify('Informe o valor', 'warning');
                    setTimeout(() => this.$refs.valor.focus(), 100);
                    return;
                }
            }

            const valor_final = this.saldos.map(e => ({
                forma_pagamento_id: e.id,
                valor: +e.valor,
                saldo: +e.saldo,
                diferenca: e.valor - e.saldo,
            }));

            this.resolve({
                confirm: true,
                valor: this.valor,
                observacao: this.observacao,
                valor_final,
            });
            this.dialog = false;
        },

        cancel() {
            this.resolve({ confirm: false });
            this.dialog = false;
        },

        init() {
            this.getSaldo();
            setTimeout(() => this.$refs.valor.focus(), 100);
        },

        getSaldo() {
            if (!this.isFechamento) {
                return;
            }

            this.loading = true;
            this.$http.get('caixa/saldo').then(resp => {
                this.saldos = resp.data.data.map(e => ({...e, ...{ valor: null }}));
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.formas-pgto-table {
    max-height: 250px;
    overflow-x: auto;
    border: 1px solid #aaa;
    padding-bottom: 2px;
}
</style>
