import store from '../store/index';

export const clone = obj => JSON.parse(JSON.stringify(obj));

export const isMobile = () => window.matchMedia('(any-pointer:coarse)').matches;

export const printDanfe = async url => {
    const html = await fetchDanfeHtml(url);

    const printWindow = window.open('', '_blank');
    printWindow.document.write(html);
    printWindow.print();
    window.innerWidth > 768 && printWindow.close();
};

export const openDanfe = async url => {
    const html = await fetchDanfeHtml(url);

    const printWindow = window.open('', '_blank');
    printWindow.document.write(html);
};

const fetchDanfeHtml = async url => {
    try {
        const { 
            access_token, 
            access_token_secret, 
            consumer_key, 
            consumer_secret 
        } = store.state.configuracoes.fiscal.credenciais;
    
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Consumer-Key': consumer_key,
                'X-Consumer-Secret': consumer_secret,
                'X-Access-Token': access_token,
                'X-Access-Token-Secret': access_token_secret,
            }
        });

        return await response.text();
    } catch (error) {
        console.log('Failed to fetch page: ', error);  
    }
}
