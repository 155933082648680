<template>
    <v-menu offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
                style="width: 90px; padding: 0 6px 0 0;"
            >
                <v-icon :color="isOpen ? 'success' : 'error'">
                    mdi-circle-medium
                </v-icon>
                <span style="font-size: 10px;">
                    {{ isOpen ? 'Aberto' : 'Fechado' }}
                </span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Alterar horário de {{ hoje.diaSemana }}</v-card-title>
            <v-card-text class="pb-6">
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-switch
                            v-model="aberto"
                            dense
                            inset
                            class="pb-2"
                            hide-details
                            :label="aberto ? 'Loja Aberta' : 'Loja Fechada'"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center" style="display: flex; align-items: baseline;">
                        <v-text-field
                            v-show="hoje.aberto"
                            @change="horario = $event"
                            :value="horario"
                            hide-details
                            outlined
                            dense
                            style="max-width: 120px;"
                            v-mask="['##:## - ##:##']"
                            placeholder="00:00 - 00:00"
                        />
                        <v-btn
                            v-if="hoje.aberto && !segundoHorarioAtivo"
                            @click="toogleSegundoHorario"
                            color="primary"
                            icon
                            small
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-text-field
                            v-if="segundoHorarioAtivo"
                            @change="segundoHorario = $event"
                            :value="segundoHorario"
                            hide-details
                            outlined
                            dense
                            style="max-width: 120px; margin-left: 10px;"
                            v-mask="['##:## - ##:##']"
                            placeholder="00:00 - 00:00"
                            :disabled="!hoje.aberto"
                        />
                        <v-btn
                            v-if="segundoHorarioAtivo"
                            @click="toogleSegundoHorario"
                            icon
                            small
                        >
                            <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { clone } from '@/utils/utils';

export default {
    name: 'OpeningHoursMenu',

    data: () => ({
        isOpen: false,
    }),

    mounted() {
        this.checkOpenStatus();
        this.intervalId = setInterval(this.checkOpenStatus, 60000);
    },

    beforeDestroy() {
        clearInterval(this.intervalId);
    },

    watch: {
        'configuracoes.horarios'() {
            this.checkOpenStatus();
        },  
    },

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        hoje() {
            return this.configuracoes.horarios?.find(f => f.isoWeekday == this.moment().isoWeekday()) || {};
        },

        aberto: {
            get() {
                return this.hoje.aberto;
            },

            set(v) {
                this.setHorarioProperty('aberto', v);
            },
        },

        horario: {
            get() {
                return this.hoje.horario;
            },

            set(v) {
                this.setHorarioProperty('horario', v);
            },
        },

        segundoHorarioAtivo: {
            get() {
                return this.hoje?.segundoHorarioAtivo;
            },

            set(v) {
                this.setHorarioProperty('segundoHorarioAtivo', v);
            },
        },

        segundoHorario: {
            get() {
                return this.hoje.segundoHorario;
            },

            set(v) {
                this.setHorarioProperty('segundoHorario', v);
            },
        },
    },

    methods: {
        ...mapMutations({
            setConfig: 'setConfig',
        }),

        checkOpenStatus() {
            this.isOpen = this.isOpenNow();
        },

        isOpenNow() {
            let horarios = this.hoje?.horario?.split(' - ');
            let horarios2 = this.hoje?.segundoHorarioAtivo ? this.hoje?.segundoHorario?.split(' - ') : '';

            if (!horarios) {
                return false;
            }

            const currentTime = this.moment();
            const abertura1 = this.moment(horarios[0], 'HH:mm');
            const fechamento1 = this.moment(horarios[1], 'HH:mm');
            const abertura2 = horarios2 ? this.moment(horarios2[0], 'HH:mm') : '';
            const fechamento2 = horarios2 ? this.moment(horarios2[1], 'HH:mm') : '';

            const abertoAgora1 = currentTime.isBetween(abertura1, fechamento1);
            const abertoAgora2 = horarios2 ? currentTime.isBetween(abertura2, fechamento2) : false;

            return this.hoje?.aberto && (abertoAgora1 || abertoAgora2);
        },

        setHorarioProperty(key, value) {
            const horarios = clone(this.configuracoes.horarios);
            horarios.map(f => {
                if (f.isoWeekday == this.moment().isoWeekday()) {
                    f[key] = value;
                }
                return f;
            });
            this.setConfig({ option: 'horarios', value: horarios });
            this.checkOpenStatus();
            this.save();
        },

        save() {
            const data = { horarios: this.configuracoes.horarios };
            this.$http.post('configuracoes/set-config', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Atualizado com sucesso!');
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
        },

        toogleSegundoHorario() {
            this.segundoHorarioAtivo = !this.hoje.segundoHorarioAtivo;
        },
    },
}
</script>

<style scoped>
.v-card__text, .v-card__title {
    word-break: normal;
    /* maybe !important  */
}
</style>
